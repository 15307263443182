import React, { FC } from "react"
import styled from "styled-components"
import InternalLink from "~/components/InternalLink"

const Wrapper = styled.div`
  margin: 100px;
  padding: 50px;
  border-radius: 30px;
  text-align: center;
  color: white;
  background-color: ${({ theme }) => theme.colors.purple};
  ${({ theme }) => theme.media.lm`
    margin: 30px;
    padding: 30px;
  `}
`

const StatusMessage = styled.div`
  font-size: 4rem;
`

const TextBox = styled.div`
  margin: 70px 0;
  ${({ theme }) => theme.media.lm`
    margin: 30px 0;
  `}
`

const Text = styled.p`
  font-size: 1.5rem;
`

const Button = styled.button`
  border: 1px solid white;
  padding: 20px 40px;
  color: white;
  background-color: ${({ theme }) => theme.colors.purple};
`

type Props = {
  status: number
  message: string
  textList: string[]
}

const ErrorTemplate: FC<Props> = ({ status, message, textList }) => (
  <Wrapper>
    <StatusMessage>
      {status} {message}
    </StatusMessage>
    <TextBox>
      {textList.map(text => (
        <Text>{text}</Text>
      ))}
    </TextBox>
    <InternalLink link="/">
      <Button>トップページ</Button>
    </InternalLink>
  </Wrapper>
)

export default ErrorTemplate
