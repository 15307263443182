import React, { FC } from "react"
import Head from "~/components/Head"
import Layout from "~/components/Layout"
import ErrorTemplate from "~/components/ErrorTemplate"

const errorDetail = {
  status: 404,
  message: "Not Found",
  desc: ["指定されたページは存在しません。", "アクセス先をご確認ください。"],
}

const NotFoundPage: FC = () => (
  <>
    <Head title={errorDetail.status.toString()} />
    <Layout>
      <ErrorTemplate
        status={errorDetail.status}
        message={errorDetail.message}
        textList={errorDetail.desc}
      />
    </Layout>
  </>
)

export default NotFoundPage
